<template>
	<transition name="fade" v-if="showModal">
		<div class="modal-mask admin">
			<div class="modal-wrapper">
				<div class="modal-dialog modal-lg" role="document">
					<div class="modal-content wider">
						<div class="modal-header">
							<h5 class="modal-title">{{ title }}</h5>
							<b-button @click="showModal = false" type="button" class="close" data-dismiss="modal" aria-label="Close">
								<span aria-hidden="true">&times;</span>
							</b-button>
						</div>
						<div class="modal-body">
							<div v-if="editEntry">
								<form ref="form" v-on:submit.prevent>
									<form-group id="input-group-4" label="Begivenhed er aktiv" label-for="active">
										<input type="checkbox" class="form-check-input" id="bookable"
											v-model="entryBeingEdited.active" style="font-size:22px">
									</form-group>

									<form-group :validator="$v.entryBeingEdited.headline" id="input-group-1"
										label="Overskrift" label-for="headline">
										<input type="text" class="form-control form-control-sm"
											v-model="entryBeingEdited.headline"
											@input="$v.entryBeingEdited.headline.$touch()" required />
									</form-group>

									<form-group id="input-group-2" label="Teaser til forsiden" label-for="teaser">
										<b-form-textarea id="desc" :state="entryBeingEdited.teaser.length >= 25"
											v-model="entryBeingEdited.teaser"
											placeholder="Skriv et kort resume til oversigten"
											@blur="$v.entryBeingEdited.teaser.$touch()" rows="2" max-rows="2" required>
										</b-form-textarea>
									</form-group>

									<form-group id="input-group-4" label="Online event?" label-for="isOnline">
										<input type="checkbox" class="form-check-input" id="bookable"
											v-model="entryBeingEdited.isOnline" style="font-size:22px"><br />
										<span class="text-muted">Hvis begivenheden kun er online, så sæt kryds
											her.</span>
									</form-group>

									<form-group id="input-group-3" label="Startdato" label-for="">
										<b-form-datepicker locale="da" class="mb-2 datebutton" style="width:350px"
											v-model="entryBeingEdited.startDate" />
										<b-form-timepicker locale="da" class="mb-2 datebutton" style="width:350px"
											v-model="entryBeingEdited.startTime" />
									</form-group>

									<form-group id="input-group-3" label="Slutdato" label-for="">
										<b-form-datepicker locale="da" class="mb-2 datebutton" style="width:350px"
											v-model="entryBeingEdited.endDate" />
										<b-form-timepicker locale="da" class="mb-2 datebutton" style="width:350px"
											v-model="entryBeingEdited.endTime" />
									</form-group>

									<form-group id="input-group-4" label="" label-for="active">
										<b-button @click="uploadClicked(entryBeingEdited.id)" variant="success">Upload
											billede</b-button>
										{{ filename }}
										<span v-if="entryBeingEdited.filename != null">
											<b-img class="previewImg" ref="previewImg" id="previewImage"
												v-bind:src="getImageUrl(entryBeingEdited.id)" />
											<b-link class="removeImageLink" @click="removeImage">Fjern billede</b-link>
										</span>
									</form-group>

									<form-group id="input-group-5" label="Brødtekst" label-for="desc">
										<html-editor ref="editor" v-bind:htmlinput="entryBeingEdited.body" />
									</form-group>
									<input style="display: none;" type="file" ref="fileInput"
										@change="onFileSelected" />
								</form>

							</div>
							<!-- *************************************************************************************************** -->
							<!-- *************************************************************************************************** -->
							<div v-else>
								De første 3 <i>aktive og kommende</i> begivenheder vises på din forside. Indlæg sorteres
								efter dato.<br />
								<br>
								<b-button size="sm" class="mb-2" variant="primary" @click="addEvent">
									<!-- <span style="font-size:22px"> -->
									<b-icon icon="plus-circle-fill" title="Tilføj ny" variant="" /> Tilføj 
								</b-button>
								<b-table-simple class="tight" small responsive>
									<b-thead>
										<b-th>Overskrift</b-th>
										<b-th class="text-center">Dato</b-th>
										<b-th class="text-center">Aktiv</b-th>
										<b-th cols="3" colspan="3">Funktioner</b-th>
									</b-thead>
									<b-tbody v-for="(event, index) in events" :key="event.id"
										:class="event.active ? 'active' : 'inactive'">
										<b-tr>
											<b-td style="width:50%" class="header">
												{{ event.headline }}
											</b-td>
											<b-td class="text-center" style="width:20%">
												{{ event.startDateTime == "0001-01-01 00:00" ? "" : event.startDateTime }}
											</b-td>
											<b-td style="width:20%" class="text-center align-top">
												{{ event.active ? "Ja" : "Nej" }}
											</b-td>
											<b-td class="align-top" style="vertical-align:middle">
												<b-icon style="font-size:17px;cursor:hand" title="Rediger"
													icon="pencil-square" @click="editEvent(event, index)" />
											</b-td>
											<b-td class="align-top" style="vertical-align:middle">
												<b-icon style="font-size:17px;cursor:hand" title="Slet"
													icon="trash-fill" @click="deleteEvent(event, index)" />
											</b-td>
										</b-tr>
										<b-tr>
											<b-td colspan="6">
												<hr />
											</b-td>
										</b-tr>
									</b-tbody>
								</b-table-simple>

								<div v-if="events.length == 0" class="sectioninfo">
									Der er ingen begivenheder. Klik på 'Tilføj begivenhed' for at oprette en.
								</div>

							</div>
						</div>
						<div v-if="editEntry" class="modal-footer">
							<button type="button" class="btn btn-primary" @click="saveForm">Gem</button>
							<button type="button" class="btn btn-secondary" @click="cancelEdit">Fortryd</button>
						</div>
						<div v-else class="modal-footer">
							<button type="button" class="btn btn-primary" @click="closeForm">Luk</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</transition>
</template>
<style type="text/css">
/* .editor {
	min-height: 52vh !important;
	max-height: 52vh !important;
} */
</style>
<style scoped>
.text-muted {
	font-size: 70% !important;
}

a.removeImageLink {
	font-size: 80%;
	text-decoration: underline;
}

img.previewImg {
	width: 40px;
	height: 40px;
	border-radius: 2px;
	border: 1px solid #aaa;
	margin-left: 4px;
	margin-right: 4px;
}

div.tight table td {
	font-size: 80%;
	border-bottom: 0px !important;
	padding-top: 2px;
	padding-bottom: 2px
}

div.tight table tbody {
	border-top: 0px solid #eee
}

div.tight table th {
	color: #aaa;
	font-weight: normal;
	font-size: 80%;
}

tbody.inactive td {
	color: rgb(204, 204, 204)
}

div.cropped {
	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
	font-size: 15px;
	line-height: 18px;
	max-height: 90px;
}

.modal-dialog,
.modal-content {
	min-height: 400px;
	height: 99vh;
	max-height: 89vh;
}

.modal-body {
	/* 100% = dialog height, 120px = header + footer */
	height: 70vh;
	overflow-y: auto;
}

.modal-mask {
	position: fixed;
	z-index: 100;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, .5);
	display: table;
	transition: opacity .3s ease;
}

.modal-wrapper {
	display: table-cell;
	vertical-align: middle;
}

@media (max-width: 994px) {
	.modal-dialog {
		min-width: calc(100% - 20px) !important;
	}
}

@media (min-width: 650px) {
	.wider {
		min-width: calc(100% - 20px);
	}

	.modal-dialog {
		max-width: auto !important;
		width: calc(100% - 20px);

	}
}
</style>
<script>
/* eslint-disable */

import HtmlEditor from './HtmlEditor.vue'
import axios from 'axios';
import { required } from 'vuelidate/lib/validators'

export default ({
	components: {
		HtmlEditor,
	},
	props: ['itemType', 'title'],
	data() {
		return {
			editEntryIndex: -1,
			editEntry: false,
			newId: -1,
			events: [],
			entryBeingEdited: null,
			showModal: false,
			clickedEntryId: -1,
			filename: null,
		}
	},
	computed: {
		practitionerId() {
			return this.$store.getters.practitionerId;
		},
		practitionerCity() {
			return this.$store.getters.practitioner.city;
		}
	},
	validations: {
		entryBeingEdited: {
			headline: { required },
			teaser: { required },
			body: { required },
		}
	},
	methods: {
		cancelEdit() {
			this.editEntry = false;
		},
		closeForm() {
			this.showModal = false;
			this.$emit("refresh");
		},
		trimChar(string, charToRemove) {
			while (string.charAt(0) == charToRemove) {
				string = string.substring(1);
			}
			while (string.charAt(string.length - 1) == charToRemove) {
				string = string.substring(0, string.length - 1);
			}
			return string;
		},
		getImageUrl(id) {
			return this.entryBeingEdited.filename == null ? `/behandlerportal/images/blank.gif` : `/behandlerportal/images/bh/cie_${id}.jpg`;
		},
		uploadClicked(entryId) {
			this.clickedEntryId = entryId;
			this.$refs.fileInput.click();
		},
		onFileSelected(event) {
			this.filename = event.target.files[0].name;
		},
		saveImage() {
			if (this.filename == null)
				return;
			console.log("name: " + this.$refs.fileInput.files[0].name);
			const fd = new FormData();
			fd.append("practitionerId", this.practitionerId);
			fd.append("entryid", this.clickedEntryId);
			fd.append("file", this.$refs.fileInput.files[0]);
			axios
				.post('/practitioner/events/saveimage', fd)
				.then(res => {
					console.log("done: " + res)
				})
				.finally(() => {
					this.fetchItems()
				});
		},
		saveForm(e) {
			e.preventDefault();
			this.entryBeingEdited.body = this.$refs.editor.getHTML();
			this.$v.$touch();
			if (this.$v.$invalid) {
				console.log("form inValid: " + this.$v.$invalid);
				return;
			}
			if(this.entryBeingEdited.startDate) {
				this.entryBeingEdited.startDateTime = this.entryBeingEdited.startDate;
				if(this.entryBeingEdited.startTime)
					this.entryBeingEdited.startDateTime += " " + this.formatTime(this.entryBeingEdited.startTime);
				else 
					this.entryBeingEdited.startDateTime += " 00:00";

				if(this.entryBeingEdited.endDate) {
					this.entryBeingEdited.endDateTime = this.entryBeingEdited.endDate;
					if(this.entryBeingEdited.endTime)
						this.entryBeingEdited.endDateTime += " " + this.formatTime(this.entryBeingEdited.endTime);
					else
						this.entryBeingEdited.endDateTime += " 00:00";

				}
			} else {
				this.entryBeingEdited.startDateTime = "";
				this.entryBeingEdited.endDateTime = "";
			}

			axios.post(`/practitioner/events/${this.practitionerId}/${this.entryBeingEdited.id}`,
				this.entryBeingEdited)
				.then(response => {
					this.clickedEntryId = response.data;
					this.saveImage();
					this.editEntry = false;
					this.fetchItems();
				})
				.catch(error => {
					alert(error.response.data);
					console.log(error + "\n" + error.response.data);
				});
		},
		formatTime(timeAsString) {
			return timeAsString.length > 5 ? timeAsString.substring(0, 5) : timeAsString;
		},
		editEvent(event, index) {
			this.filename = null;
			
			const startDateTime = new Date(event.startDateTime);
			const endDateTime = new Date(event.endDateTime);

			this.entryBeingEdited = {
				id: event.id,
				headline: event.headline,
				teaser: event.teaser,
				body: event.body,
				active: event.active,
				isOnline: event.isOnline,
				startDateTime: null,
				endDateTime: null,
				filename: event.filename,
			}
			if(event.startDateTime == "0001-01-01 00:00")
			{
				this.entryBeingEdited.startDate = null;
				this.entryBeingEdited.startTime = null;
			} else {
				this.entryBeingEdited.startDate = this.getDatePortion(startDateTime);
				let time = this.getTimePortion(startDateTime).substring(0, 5);
				this.entryBeingEdited.startTime = time == "00:00" ? null : time;
			}
			if(event.endDateTime == "9999-12-31 23:59")
			{
				this.entryBeingEdited.endDate = null;
				this.entryBeingEdited.endTime = null;
			} else {
				this.entryBeingEdited.endDate = this.getDatePortion(endDateTime);
				let time = this.getTimePortion(endDateTime).substring(0, 5);
				this.entryBeingEdited.endTime = time == "00:00" ? null : time;
			}
			
			this.editEntryIndex = index;
			this.editEntry = true;
		},
		getDatePortion(d) {
			return `${d.getFullYear()}-${(d.getMonth() + 1).pad(2)}-${d.getDate().pad(2)}`;
		},
		getTimePortion(d) {
			return d.getHours().pad(2) + ":" + d.getMinutes().pad(2);
		},
		deleteEvent(event) {
			axios
				.delete(`/practitioner/events/${this.practitionerId}/delete/${event.id}`)
				.then(() => {
					this.events = null;
					this.fetchItems();
				})
				.catch(error =>
					console.log(error)
				);
		},
		removeImage() {
			axios
				.delete(`/practitioner/events/${this.practitionerId}/deleteimage/${this.entryBeingEdited.id}`)
				.then(() => {
					this.entryBeingEdited.filename = null;
				})
				.catch(error =>
					console.log(error)
				);
		},
		addEvent() {
			this.editEntryIndex = -1;
			this.entryBeingEdited = {
				id: -1,
				headline: "",
				teaser: "",
				body: "",
				active: true,
				startDate: null,
				startTime: null,
				endDate: null,
				endTime: null,
			};
			this.editEntry = true;
		},
		fetchItems() {
			console.log("Fetch items..");
			axios
				.get(`/practitioner/events/${this.practitionerId}?all=true&itemCount=1000&orderby=newestfirst`)
				.then(response => {
					this.events = response.data;
				})
				.catch(error =>
					console.log(error)
				);
		},
		show() {
			this.fetchItems();
			this.showModal = true;
		}
	},
})
Number.prototype.pad = function (size) {
	var s = String(this);
	while (s.length < (size || 2)) { s = "0" + s; }
	return s;
}
</script>

