<template>
	<div>
		<section class="footer" v-if="!isPractitionerAdmin() && !isLiveEvent()">
			<h1 class="intro">
				Har du spørgsmål til den nye platform eller har du brug for hjælp til at finde en behandler, så er du
				altid
				velkommen til at skrive til os eller vores sundhedsfaglige eksperter på minSundhed.com
			</h1>
			<div class="container">
				<div class="row " v-if="!isPractitionerAdmin()">
					<div class="col-lg-4">
						<div class="contact-box">
							<div class="title">
								KONTAKT
							</div>
							<p class="info">
								kontakt@cfhs.dk <br>
								Hjertet bag
							</p>
							<p class="info">
								minsundhed ApS <br>
								CVR 42284610
							</p>
						</div>
					</div>
					<div class="col-lg-4">
						<div class="contact-box">
							<div class="title">
								NYTTIGE LINKS
							</div>
							<a href="https://minsundhed.com/ekspertforum" class="link">minSundhed.com Ekspertforum</a>
							<a href="https://cms.minsundhed.com/rabatter-og-samarbejdspartnere" class="link">Vores
								samarbejdspartnere</a>
							<a href="https://minsundhed.com/opretbruger" class="link">Opret gratis brugerprofil</a>
							<terms-and-conditions ref="termsAndConditions"></terms-and-conditions>
							<span class="link" @click="$refs.termsAndConditions.show(2)">Brugerbetingelser</span>
							<a href="https://minsundhed.com/cookie-politik" class="link">Cookiepolitik</a>
						</div>
					</div>
					<div class="col-lg-4">
						<div class="contact-box">
							<div class="title">
								ER DU HELHEDSORIENTERET BEHANDLER?
							</div>
							<p class="info">
								Opret din profil i dag og kom med det samme med i søgeresultaterne her på
								minHolistiskeBehandler.dk.
							</p>
							<p class="info">
								Bonus! Du bliver også inviteret med i vores nye store faglige fællesskab for
								helhedsorienterede behandlere.
							</p>
						</div>
					</div>
				</div>
				<div class="row mt-5">
					<p class="text-center text-white copyright">© Copyright 2022 minSundhed</p>
				</div>
			</div>
		</section>
		<section class="footerAdmin" v-if="isPractitionerAdmin()">
			<p class="text-center text-white copyright">© Copyright 2022 minSundhed</p>
		</section>
		
	</div>
</template>

<style lang="scss">
.footerAdmin {
	padding: 10px;
	margin-top:20px;
	background-color: #1692b6;
	p {margin:0}
	.copyright {
		font-size: 13px;
		font-family: "Raleway-Regular";
	}
}

.footer {
	padding: 90px 50px 18px;
	background-color: #1692b6;

	@media (max-width:667px) {
		padding: 90px 25px 18px;
	}

	.col-lg-4 {
		@media (max-width:667px) {
			padding-left: 0 !important;
			padding-right: 0 !important;

		}
	}

	.copyright {
		font-size: 13px;
		font-family: "Raleway-Regular";
	}

	.intro {
		font-size: 38px;
		font-family: 'Raleway-Light';
		color: #ffffff;
		margin-bottom: 90px;
		width: 70%;

		@media (max-width:1024px) {
			font-size: 30px;
			line-height: 1.5;
			margin-bottom: 50px;
			width: 90%;
		}

		@media (max-width:667px) {
			font-size: 21px;
			line-height: 1.8;
			margin-bottom: 50px;

		}

	}

	.contact-box {
		margin-bottom: 35px;

		.title {
			font-size: 14px;
			font-family: 'Raleway-Light';
			color: #fff;
			margin-bottom: 20px;
		}

		.link {
			font-size: 18px;
			font-family: "Raleway-Regular";
			color: #ebe9f1;
			text-decoration: none;
			line-height: 2.3;
			display: block;
			cursor: pointer;

			&:hover {
				color: #65e8ff;
			}
		}

		.info {
			font-size: 18px;
			font-family: "Raleway-Regular";
			color: #ebe9f1;
		}
	}
}
</style>
<script>
import axios from 'axios';
import TermsAndConditions from '../SignUp/TermsAndConditions.vue';
export default {
	components: {
		TermsAndConditions
	},
	methods: {
		testRequest() {
			axios.get(`api/practitioner/entity/checksomedata?practitionerId=2`)
				.then((res) => {
					//this.showModal = false;
					console.log("res:", res);
				})
				.catch(error => {
					console.log(error);
				});
		},
		showFooter() {
			return this.$route.path.indexOf("admin") < 0;
		},
		isPractitionerAdmin() {
			return this.$route.path.indexOf("kalender") > 0;
		},
		isLiveEvent() {
			return this.$route.path.indexOf("live") > 0;
		},
	},
	
	// watch: {
	// 	'$route': {
	// 		handler: function (to) {
	// 			if(this.$route.path.indexOf("admin") > -1)

	// 		},
	// 		deep: true,
	// 		immediate: true
	// 	},
	// },
}
</script>