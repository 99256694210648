<template>
	<section class="registration__5">
		<div class="title-container">
			<div class="description">
				<div>
					<b>Et varmt velkommen til minHolistiskeBehandler.dk</b>
					<span class="heart-icon-space">
						<img src="../../../public/behandlerportal/images/full_heart.png" alt="full heart" width="15" />
					</span>
				</div>
				<div>
					<p>Vi glæder os over at have dig med på rejsen, og vil gøre vores absolut bedste for at hjælpe dig
						med
						at blive set, anerkendt og styrket i din praksis som behandler. </p>
					<p><b style="color:red;font-weight: 500;">Du modtager om et øjeblik en mail fra os på den
							mailadresse, du har tilmeldt dig med, Her finder du bl.a et
							link med direkte adgang til opsættelse af din personlige behandlerprofil og en indbydelse
							til at blive del
							af vores fantastiske behandlerfællesskab. Hold øje med spam-mappen! :)</b>
					</p>
				</div>
				<div>
					<b>Sammen bliver vi stærkere</b>
				</div>
				<div>
					<p>I vores fællesskab kan du sparre med andre behandlere, deltage helt gratis i spændende
						kompetenceøgende
						indlæg, finde samarbejder eller måske “bare” nogle ligesindede, du dele hverdagen som
						selvstændig
						behandler med. Vi tror også på, at vi ved at samle os, kan få en større stemme i den offentlige
						debat og
						øge brugen af naturlig forebyggende behandling i befolkningen.
					</p>
				</div>
				<div>
					<b>Bare rolig, der er ingen kedelige overraskelser</b>
				</div>
				<div>
					<p>Du har ikke bundet dig til noget og vi har ingen skjulte intentioner. En profilside er gratis i
						hele 2022,
						hvor vi arbejder på at udbygge platformen og dens besøgende. Omkring årsskiftet vil du modtage
						en mail
						hvori vi spørger til dit ønske om at fortsætte i 2023 eller ej. Her er du helt fri til at takke
						nej,
						men vi håber selvfølgelig inden da, at have givet dig så megen lyst til at fortsætte, at du
						tager med
						på vores videre fælles rejse :) Og bare rolig, vi lover dig, at det bliver til en pris som alle
						kan være med på!
					</p>
				</div>
				<div>
					<b>Inddragelse og kærligt medejerskab</b>
				</div>
				<div>
					<p>Vi ønsker behandlere på vores markedsplads, som er glade for at være med! Som føler sig set og
						styrket via
						vores markedsføringsindsatser, fællesskab, vidensdeling, kompetenceøgning og en aktiv
						inddragelse af jer
						som behandlere. Derfor vil vi også jævnligt spørge ind til jeres ønsker og behov, så vi kan give
						jer lige
						præcis det, som I har brug for. Om du vil deltage i denne feedback er selvfølgelig fuldkommen
						frivillig :)
					</p>
				</div>
			</div>
		</div>
	</section>
</template>
<style lang="scss" scoped>
@use "./colors.scss" as *;

.title-container {
	width: 100%;
	margin: 0 auto;

	.title {
		font-size: 38px;
		font-weight: 200;
		line-height: 48px;
		text-align: center;
		color: $black;
	}

	.description {
		text-align: center;
		color: $charcoal-gray;
		font-weight: 500;

		.heart-icon-space {
			margin-bottom: 0px;
			margin-left: 10px;
		}

		b {
			font-size: 19px;
		}

		.highlight {
			font-style: italic;
			color: #d0021b;
			font-weight: normal;
		}
	}
}

/*small phone*/
@media (max-width: 374px) {
	.title-container {
		max-width: 327px;
		margin-top: -10px;

		.description {
			margin-top: 10px;
			margin-bottom: 20px;
			color: $charcoal-gray;
			font-size: 14px;
			font-weight: 600;
			line-height: 28px;
		}
	}
}

/*big phone*/
@media (min-width: 375px) {
	.title-container {
		max-width: 327px;
		margin-top: -10px;

		.description {
			margin-top: 10px;
			margin-bottom: 20px;
			color: $charcoal-gray;
			font-size: 14px;
			font-weight: 600;
			line-height: 28px;
		}

	}
}

/*small tablet*/
@media (min-width: 768px) {
	.title-container {
		max-width: 600px;

		.tittle {
			font-size: 48px;
			line-height: 48px;
			color: $dark-blue;
		}

		.description {
			margin-bottom: 55px;
			margin-top: 30px;
			font-weight: 500;
			font-size: 15px;
			line-height: 26px;
			color: $rhino-gray;
			text-align: center;
		}
	}
}

/*big tablet*/
@media (min-width: 993px) {
	.title-container {
		max-width: 730px;
		margin-top: -25px;

		.description {
			margin-top: 34px;
			font-size: 15px;
			margin-bottom: 30px;
		}
	}
}

/*laptop*/
@media (min-width: 1264px) {}

/*pc*/
@media (min-width: 1664px) {}
</style>

<script>
// import { mapState } from "vuex";
export default {
	components: {},
	data() {
		return {}
	},
	computed: {},
	methods: {
		endRegistration: function () {
			this.$emit('endRegistration');
		},
		scrollToTop() {
			//Check if SSR
			if (window.scrollTo) {
				window.scrollTo(0, 88);
			}
		},
	},
	created() {
		this.scrollToTop();
	}
}
</script>