import { Node } from '@tiptap/core';

const CustomDiv = Node.create({
    name: 'customDiv', // Name it uniquely to avoid conflicts
    group: 'block', // Set it as a block-level element
    content: 'block*', // Allow other block nodes within the div, if needed

    parseHTML() {
        return [{ tag: 'div' }];
    },

    renderHTML({ HTMLAttributes }) {
        return ['div', HTMLAttributes, 0];
    },

    addAttributes() {
        return {
            class: {
                default: null,
            },
        };
    },
});

export default CustomDiv;
