<template>
	<div class="discount-container">
		<div>
			<router-link to="/admin">Tilbage til admin</router-link><br />
			<br/>
		</div>
		<div>
			<div class="event-select">
				<div style="padding-bottom:20px;" v-if="selectedEventId != 0"><button @click="newEvent"
						class="save-button">Opret nyt event</button>
				</div>
				<label for="event-select">Vælg Event:</label>
				<select v-model="selectedEventId" @change="handleSelectionChange">
					<option disabled value="">Please select an event</option>
					<option v-for="event in events" :key="event.id" :value="event.id">{{ event.eventName }}</option>
				</select>
			</div>
		</div>

		<div v-if="activeEvent" class="discount-table">
			<div class="full-span" v-if="selectedEventId > 0"><button @click="duplicate" class="">Opret kopi</button>
				(vil blive
				oprettet som ikke-aktiv)</div>
			<h3 class="full-span">{{ selectedEventName }}</h3>
			<div></div>
			<div class="field"><input v-model="activeEvent.active" type="checkbox" />&nbsp;&nbsp;Aktiv</div>
			<div class="field">Navn</div>
			<div class="input"><input v-model="activeEvent.eventName" type="text" /></div>
			<div class="field">Price</div>
			<div class="input"><input v-model="activeEvent.price" type="number" step="0.01" /></div>
			<div class="field"></div>
			<div class="input"><input v-model="activeEvent.hasVat" type="checkbox" />&nbsp;&nbsp;Har moms </div>
			<div class="field">Redirect Url</div>
			<div class="input"><input v-model="activeEvent.redirectUrl" type="text" /></div>
			<div class="field">Event adresse</div>
			<div class="input"><textarea v-model="activeEvent.eventAddress" type="text" rows="3"></textarea></div>
			<div class="field">SoME Image</div>
			<div class="input"><input v-model="activeEvent.someImage" type="text" /></div>
			<div class="field">Sortering</div>
			<div class="input"><input v-model="activeEvent.sortOrder" type="number" /></div>
			<!-- ****************** -->
			<div class="section">Tilmelding side</div>
			<div class="field">Header</div>
			<div class="input">
				<EventHtmlEditor ref="editor_header" id="editor_header" PageeditorHeight="450px"
					v-bind:htmlinput="activeEvent.header" />
			</div>
			<div class="field">Footer</div>
			<div class="input">
				<EventHtmlEditor ref="editor_footer" id="editor_footer" editorHeight="300px"
					v-bind:htmlinput="activeEvent.footer" />
			</div>
			<!-- ************ -->
			<div class="section ">Email template</div>
			<div class="field">Fra email</div>
			<div class="input"><input v-model="activeEvent.fromEmail" type="text" /></div>
			<div class="field">Mail emne</div>
			<div class="input"><input v-model="activeEvent.mailSubject" type="text" /></div>
			<div class="field">Hvor og hvornår</div>
			<div class="input">
				<EventHtmlEditor ref="editor_mailWhenAndWhere" id="editor_mailWhenAndWhere" editorHeight="200px"
					v-bind:htmlinput="activeEvent.mailWhenAndWhere" />
			</div>
			<div class="field">See you...</div>
			<div class="input">
				<EventHtmlEditor ref="editor_mailSeeYou" id="editor_mailSeeYou" editorHeight="200px"
					v-bind:htmlinput="activeEvent.mailSeeYou" />
			</div>
			<div class="field">Mail produkt navn</div>
			<div class="input"><input v-model="activeEvent.mailProductLine" type="text" /></div>
			<div></div>
			<div><button @click="saveEvent" class="save-button">Save</button>&nbsp;&nbsp;<button style="float:right"
					@click="deleteEvent" class="delete-button">Slet event</button></div>
		</div>
	</div>
</template>

<style scoped>
.discount-container {
	padding-top:50px;
	margin: 40px auto;
	padding: 30px;
	background-color: #f9f9f9;
	border-radius: 8px;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.event-select {
	margin-bottom: 20px;
}

label {
	font-weight: bold;
	margin-right: 10px;
}

select {
	padding: 8px;
	border-radius: 4px;
	border: 1px solid #ccc;
}

.discount-table {
	display: grid;
	grid-template-columns: 1fr 3fr;
	gap: 10px;
	margin-top: 20px;
}

.field,
.input {
	display: flex;
	align-items: center;
}

.full-span {
	grid-column: span 2;
}

.section {
	grid-column: span 2;
	padding: 20px;
	border-radius: 8px;
	background-color: #2196f380;
	font-size: 1em;
	/* margin: 10px 0px 10px 0px;
	font-weight: bold; */
	display: flex;
}

textarea,
input[type="text"],
input[type="number"] {
	width: 100%;
	padding: 8px;
	border-radius: 4px;
	border: 1px solid #ccc;
	margin: 0 0 10px 0;
}

button {
	padding: 8px 12px;
	border: none;
	border-radius: 4px;
	cursor: pointer;
}

.save-button {
	background-color: #4caf50;
	color: white;
	margin-right: 5px;
}

button:hover {
	opacity: 0.8;
}
</style>

<script>
import axios from 'axios';
import EventHtmlEditor from './editor/EventHtmlEditor.vue';

export default {
	components: {
		EventHtmlEditor
	},
	data() {
		return {
			selectedEventId: null,
			events: [],
			activeEvent: null,
			selectedTemplateId: null,
			templates: [],
		};
	},
	computed: {
		selectedEventName() {
			let activeEvent = this.events.find(e => e.id === this.selectedEventId);
			return activeEvent ? activeEvent.eventName : 'Ny event';
		},
		frontpageTemplates() {
			return this.templates.filter(e => e.section === "FrontPage");
		},
	},
	methods: {
		insertTemplate(id, editorId) {
			const editor = this.$refs[editorId];
			if (editor) {
				const template = this.templates.find(e => e.id.toString() === id.toString());
				editor.setEditorContents(template.contents);
			}
		},
		handleSelectionChange() {
			if (this.selectedEventId === null) {
				this.activeEvent = {
					id: 0,
					active: false,
					eventName: '',
					price: 0,
					hasVat: false,
					redirectUrl: '',
					eventAddress: '',
					someImage: '',
					fromEmail: '',
					sortOrder: 0,
					header: '',
					footer: '',
					mailSubject: '',
					mailWhenAndWhere: '',
					mailSeeYou: '',
					mailProductLine: ''
				};
				this.selectedEventId = 0;
			} else {
				this.activeEvent = this.events.find((event) => event.id === this.selectedEventId);
			}
			// Update the URL to reflect the selected event
			if (this.activeEvent && this.activeEvent.id) {
				this.$router.push({ name: 'LiveEventEdit', query: { eventid: this.activeEvent.id } });
			}
		},
		duplicate() {
			if (!this.activeEvent) {
				console.error("No event selected to delete.");
				return;
			}
			axios.post(`/LiveEvents/duplicate/${this.activeEvent.id}`)
				.then((res) => {
					this.$router.push('/admin/liveeventedit?eventid=' + res.data.duplicateEventId);
				})
				.catch((error) => {
					console.error("Error deleting template:", error);
				});
		},
		deleteEvent() {
			if (!this.activeEvent) {
				console.error("No event selected to delete.");
				return;
			}
			if (confirm("ER DU HELT SIKKER?")) {
				axios.delete(`/LiveEvents/delete/${this.activeEvent.id}`)
					.then(() => {
						this.$router.push('/admin/LiveEventEdit');
					})
					.catch((error) => {
						console.error("Error deleting template:", error);
					});
			}
		},
		newEvent() {
			this.selectedEventId = 0;
			//this.activeEvent = null;
			this.$router.push({ name: 'LiveEventEdit', query: { eventid: 0 } });
			this.handleSelectionChange();
		},
		async saveEvent() {
			if (!this.activeEvent) {
				console.error("No event selected to save.");
				return;
			}
			try {
				this.activeEvent.header = this.$refs.editor_header.getHTML();
				this.activeEvent.footer = this.$refs.editor_footer.getHTML();
				this.activeEvent.mailWhenAndWhere = this.$refs.editor_mailWhenAndWhere.getHTML();
				this.activeEvent.mailSeeYou = this.$refs.editor_mailSeeYou.getHTML();
				const response = await axios.post(`/LiveEvents/save`, this.activeEvent);

				// Optionally handle the response to ensure the save was successful.
				if (response.status === 200) {
					console.log("Event saved successfully!");
					this.activeEvent = null;
					// Optionally update the events list or inform the user about the success.
					this.fetchEvents(); // Refresh the event list if needed.
				} else {
					console.error("Failed to save the event. Please try again.");
					alert("Failed to save the event. Please try again.");
				}
			} catch (error) {
				console.error("Error saving the event:", error);
				alert("There was an error saving the event. Please check your connection or try again later.");
			}
		},
		async fetchEditorTemplates() {
			await axios.get('/editorTemplates')
				.then((response) => {
					this.templates = response.data;
				})
				.catch((error) => {
					console.error("Error fetching events:", error);
				});
		},
		async fetchEvents() {
			await axios.get('/liveevents')
				.then((response) => {
					this.events = response.data;
				})
				.catch((error) => {
					console.error("Error fetching events:", error);
				});
		},
		async fetchEventById(eventId) {
			this.selectedEventId = parseInt(eventId);
			this.activeEvent = this.events.find((event) => event.id === this.selectedEventId);
		},
	},
	async mounted() {
		await this.fetchEditorTemplates();
		await this.fetchEvents();
		const eventId = this.$route.query.eventid;
		if (eventId) {
			this.fetchEventById(eventId);
		} else {
			this.handleSelectionChange(); // Initialize as new event
		}
	},
};
</script>
