<template>
	<div :class="getSortBarClass()" style="border: 0px solid red;">
		<div v-if="isAboutPage()" class="about">
			<p class="text-center title">Om minHolistiskeBehandler<br />
				<span>Teamet og visionen</span>
			</p>
		</div>
		<div v-else class="container">
			<p class="text-center title" v-if="isRootPage()">Du kan også vælge at søge på specifik behandling</p>
			<div class="sort">
				<div class="items_ searchResult" v-if="!isRootPage()">
					<div class="search mt-3">
						<input placeholder="Søg på symptom eller lidelse" type="search" v-on:keyup.enter="doSearch"
							v-model="searchValue" name="" id="">
						<button class="d-flex align-items-center justify-content-center" @click="doSearch"><i
								class="bi bi-search"></i></button>
					</div>
					<label for="" class="fw-bold text-white text-center" style="padding-top:20px">eller</label>
				</div>
				<div class="items_">
					<label>TYPE BEHANDLING</label>
					<div class="dropdown">
						<multiselect :taggable="false" :options="skills" placeholder=""
							deselectLabel="Klik for at fjerne valget" selectLabel="Klik for at vælge"
							:custom-label="skillWithCount" selectedLabel="Valgt" v-model="treatmentSelection"
							@input="onTreatmentChange" track-by="id" label="title" id="treatment" />
					</div>
					<span v-if="isRootPage()"><br /></span>
				</div>
			</div>
		</div>
	</div>
</template>
<style lang="scss">
@use "sass:color";

.about {
	display: block;
	font-family: Raleway;
	padding-top:25px;

	.title {
		font-size: 38px !important;
		font-family: Raleway;

		span {
			font-size: 28px !important;
			font-family: Raleway;
			color: white !important;
		}
	}

}

.smaller {
	margin-top: 0px !important;
}

.title {
	padding-top: 0px !important;
}

.sortbar {
	background: #00000073;
	padding: 40px 40px;
	margin-top: 140px;
	display: block;

	@media(max-width:768px) {
		// padding: 15px;
	}

	@media(max-width:600px) {
		// position: absolute;
	}

	.title {
		font-size: 21px;
		color: #fff;
		font-family: 'Raleway-SemiBold';
		margin-bottom: 24px;

		@media(max-width:768px) {
			margin-bottom: 8px;
			font-size: 16px;
		}
	}

	.sort {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-wrap: wrap;
		column-gap: 18px;

		@media(max-width:768px) {
			transform: translateY(20px);
		}

		.items_ {
			flex-basis: 300px;

			.btn-secondary {
				background-color: white !important;
				text-align: left !important;
				color: black;
			}

			&.searchResult {
				display: flex;
				align-items: center;
				justify-content: space-between;

				@media(max-width:768px) {
					flex-wrap: wrap;
					justify-content: center;

					.search input {
						max-width: 80vw;
					}
				}

				label {
					margin-left: 15px;
				}
			}

			.dropdown-toggle {
				white-space: nowrap;
				height: 55px;
				border-radius: 4px;
				outline: 0;
				border: 0;
				padding: 0 18px;

				@media(max-width:333px) {
					height: 45px;
				}
			}

			.dropdown-menu.show {
				width: 100%;
			}

			.dropdown-toggle::after {
				text-align: right;
				position: absolute;
				right: 20px;
				top: 28px;
				border-top: 0.4em solid;
				border-right: 0.4em solid transparent;
				border-bottom: 0;
				border-left: 0.4em solid transparent;

				@media(max-width:333px) {
					top: 22px;
				}
			}

			label {
				font-size: 14px;
				color: #fff;
				font-family: 'Raleway-Medium';
			}

			.btn-theme {
				@media(max-width:600px) {
					transform: translate(20px, 35px);
				}
			}
		}
	}

	.search {
		position: relative;

		@media(max-width:333px) {
			transform: translateY(-16px);
		}

		input {
			width: 350px;
			max-width: 350px;
			margin: 20px 0;
			height: 60px;
			border-radius: 44px;
			border: 0;
			outline: 0;
			padding: 0 25px;
			font-size: 16px;
			font-family: 'Raleway-SemiBold';
			color: #595858;

			@media(max-width:768px) {
				// min-width: 81vw;
				max-width: 350px !important;
			}
		}

		button {
			height: 56px;
			width: 56px;
			border-radius: 50%;
			border: 0;
			position: absolute;
			right: 2px;
			top: 22px;
			background: #0096b0;
			color: #fff;
			font-size: 1.4rem;
			position: absolute;
			transition: all 0.3s ease;

			&:hover {
				background: color.adjust(#0096b0, $lightness: -3%);
			}
		}
	}
}
</style>
<script>
import axios from 'axios'
import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.min.css'

export default {
	components: {
		Multiselect
	},
	data() {
		return {
			searchValue: '',
			treatmentSelection: null,
			skills: [],
			treatmentId: -1,
		}
	},
	watch: {
		'$route': {
			handler: function (to) {
				this.searchValue = to.query.q;
			},
			deep: true,
			immediate: true
		},
	},
	methods: {
		getSortBarClass() {
			return this.isRootPage() ? "sortbar" : "sortbar smaller";
		},
		isRootPage() {
			return this.$route.path == "/" ? true : false;
		},
		isAboutPage() {
			return this.$route.path.indexOf("om-") > -1 ? true : false;
		},
		onTreatmentChange(treatmentValue) {
			this.searchValue = "";
			this.treatmentId = treatmentValue?.id ?? -1;
			this.doSearch();
		},
		skillWithCount({ title, count }) {
			return count < 0 ? `${title}` : `${title} (${count})`
		},
		doSearch() {
			if (this.searchValue == undefined)
				this.searchValue = "";
			this.$router.push(`/search?q=${this.searchValue}&treatmentId=${this.treatmentId}`);
		},
		fetchItems() {
			axios
				.get('/practitioner/skills/allinuse')
				.then(response => {
					this.skills = response.data;
					this.skills.splice(0, 0, { title: "- Alle -", id: -1, count: -1 });
				})
				.catch(error =>
					console.log(error)
				);
		}
	},
	created() {
		this.fetchItems();
	}
}
</script>