<template>
	<div>
		<div class="editor" v-if="editor" :style="{ height: editorHeight + ' !important' }">
			<menu-bar class="editor__header" :editor="editor" />
			<editor-content class="editor__content" :editor="editor" />
		</div>
	</div>
</template>
<style lang="scss">
.editor {
	display: flex;
	flex-direction: column;
	color: #0D0D0D !important;
	background-color: #FFF;
	border: 3px solid #0D0D0D;
	border-radius: 0.75rem;
	//max-height: 250px;

	&__header {
		display: flex;
		align-items: center;
		flex: 0 0 auto;
		flex-wrap: wrap;
		padding: 0.25rem;
		border-bottom: 3px solid #0D0D0D;
	}

	&__content {
		padding: 1.25rem 1rem;
		flex: 1 1 auto;
		overflow-x: hidden;
		overflow-y: scroll;
		-webkit-overflow-scrolling: touch;
	}

	&__footer {
		display: flex;
		flex: 0 0 auto;
		align-items: center;
		justify-content: space-between;
		flex-wrap: wrap;
		white-space: nowrap;
		border-top: 3px solid #0D0D0D;
		font-size: 12px;
		font-weight: 600;
		color: #0D0D0D;
		white-space: nowrap;
		padding: 0.25rem 0.75rem;
	}
}
</style>
<style lang="scss">
@import './cabinet-grotesk.css';

.editor__content {
	padding: 0px !important;
}

/* Basic editor styles */
.ProseMirror {
	>*+* {
		margin-top: 0.75em;
	}

	ul,
	ol {
		padding: 0 1rem;
	}

	h2 {
		font-size: 20px;
	}

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		line-height: 1.1;
	}

	p {
		line-height: 1.2 !important;
		font-size: 0.95em !important;
		float: none !important;
		margin-top: 0px !important;
	}

	code {
		background-color: rgba(#616161, 0.1);
		color: #616161;
	}

	pre {
		background: #0D0D0D;
		color: #FFF;
		font-family: 'JetBrainsMono', monospace;
		padding: 0.75rem 1rem;
		border-radius: 0.5rem;

		code {
			color: inherit;
			padding: 0;
			background: none;
			font-size: 0.8rem;
		}
	}

	mark {
		background-color: #FAF594;
	}

	img {
		max-width: 100%;
		height: auto;
	}

	hr {
		margin: 1rem 0;
	}

	blockquote {
		padding-left: 1rem;
		border-left: 2px solid rgba(#0D0D0D, 0.1);
	}

	hr {
		border: none;
		border-top: 2px solid rgba(#0D0D0D, 0.1);
		margin: 2rem 0;
	}

	ul[data-type="taskList"] {
		list-style: none;
		padding: 0;

		li {
			display: flex;
			align-items: center;

			>label {
				flex: 0 0 auto;
				margin-right: 0.5rem;
				user-select: none;
			}

			>div {
				flex: 1 1 auto;
			}
		}
	}

	/* **************************************************************************************** */
	.container {
		flex-direction: column;
		align-items: flex-start;
		background-color: #0f3737;
		text-align: left;
		color: #fff;
		font-family: CabinetGrotesk-Medium, sans-serif;
		font-size: 15px;
		text-rendering: optimizeLegibility;

		p {
			color: white
		}

		h4 {
			font-size: clamp(10px, 2.5vw, 15px);
			font-family: CabinetGrotesk-Bold;
			color: #ff7757;
			text-transform: uppercase;
			letter-spacing: 2px;
			margin-bottom: 20px;
			margin-top: 20px;
			text-align: left
		}

		.ct {
			border: 0px solid red;
			display: flex;
			flex-direction: row;

			.when {
				color: #ff7757;
				text-transform: uppercase;
				letter-spacing: 1px;
			}

			p.bold {
				font-family: 'CabinetGrotesk-Bold';
			}

			div.intro {
				text-wrap: balance;
				border: 0px solid blue;
				text-align: left;

				h1 {
					font-size: clamp(20px, 4.5vw, 40px);
					line-height: 100%;
					border: 0px solid red;
					margin-bottom: 20px;
					color: #fff;
				}

				p {
					padding-bottom: 1rem;
					font-size: 15px;
					line-height: normal;
					color: inherit;
				}

			}

			div.speakers {
				border: 0px solid blue;

				div.collage {
					display: flex;
					flex-wrap: nowrap;
					gap: 15px;

					div.speaker {
						border: 0px solid blue;
						width: clamp(100px, 20vw, 200px);

						.speaker-info {
							font-size: 16px;
							line-height: normal;
							color: #fff;
							padding-top: 15px;
							text-align: left;

							b {
								font-family: 'CabinetGrotesk-Bold';
								font-size: 110%;
							}
						}

						img {
							width: clamp(80%, 80%, 100%);
							//width: 80%;
							position: relative;
							border-radius: 50%;
							// padding-right: 20px;
							height: 50%;
							object-fit: cover;
							object-position: top;
						}
					}
				}
			}
		}
	}

	/*********************************************** */

}
</style>
<script>
// import Paragraph from '@tiptap/extension-paragraph';
// import Bold from '@tiptap/extension-bold';

import { Editor, EditorContent } from '@tiptap/vue-2'
import StarterKit from '@tiptap/starter-kit'
import MenuBar from './MenuBar.vue'
import Image from '@tiptap/extension-image';
import Heading from '@tiptap/extension-heading';
import CustomDiv from './CustomDiv';

export default ({
	components: {
		EditorContent,
		MenuBar
	},
	props: {
		htmlinput: String,
		editorHeight: String,
	},
	data() {
		return {
			editor: null,
		}
	},
	methods: {
		getHTML() {
			return this.editor.getHTML();
		},
		setEditorContents(value) {
			if (this.editor) {
				this.editor.commands.setContent(value);
			}
		},
		test() {
			console.log("editor contents: " + this.getHTML());
		},
		cleanPastedHTML(html) {
			const allowedTags = ['p', 'strong', 'h1', 'h2', 'h3', 'h4', 'div', 'a', 'img'];
			const doc = new DOMParser().parseFromString(html, 'text/html');

			doc.body.querySelectorAll('*').forEach((node) => {
				if (!allowedTags.includes(node.nodeName.toLowerCase())) {
					node.replaceWith(...node.childNodes); // Remove disallowed tags but keep content
				}
			});
			return doc.body.innerHTML;
		},
		initializeEditor() {
			if (!this.editor) {
				this.editor = new Editor({
					extensions: [
						StarterKit,
						CustomDiv,
						Image,
						Heading.configure({
							levels: [1, 2, 3, 4]
						}),
						// Add other extensions here
					], 
					content: '<p></p>',
					transformPastedHTML: this.cleanPastedHTML,
				});
				this.setEditorContents(this.htmlinput);
			}
		},
		retryInitializeEditor() {
			this.initializationAttempts += 1;
			if (this.initializationAttempts < 3 && !this.editor) {
				setTimeout(() => {
					this.initializeEditor();
				}, 100);
			}
		},
	},
	computed: {
		//  this.editor.content = props["htmlinput"];
		//}
	},
	mounted() {
		this.$nextTick(() => {
			this.initializeEditor();
			this.retryInitializeEditor(); // Retry if the first attempt fails
		});
	},
	beforeDestroy() {
		if (this.editor) {
			this.editor.destroy();
			this.editor = null;  // Reset to prevent re-initialization
		}
	},
})
</script>
<!-- 
<div>Hello!</div> 
-->
