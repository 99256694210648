import { render, staticRenderFns } from "./Registration_1.vue?vue&type=template&id=61583263&scoped=true"
import script from "./Registration_1.vue?vue&type=script&lang=js"
export * from "./Registration_1.vue?vue&type=script&lang=js"
import style0 from "./Registration_1.vue?vue&type=style&index=0&id=61583263&prod&scoped=true&lang=css"
import style1 from "./Registration_1.vue?vue&type=style&index=1&id=61583263&prod&lang=css"
import style2 from "./Registration_1.vue?vue&type=style&index=2&id=61583263&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "61583263",
  null
  
)

export default component.exports