<template>
	<section class="registration__1">
		<transition name="fade" v-if="isAlreadyPractitioner">
			<div class="modal-mask admin">
				<div class="modal-wrapper">
					<div class="modal-dialog" role="document">
						<div class="modal-content wider">
							<div class="modal-header">
								<h5 class="modal-title">Du er allerede oprettet som behandler ❤️</h5>
								<b-button type="button" class="close" @click="$router.push('/')" data-dismiss="modal"
									aria-label="Close">
									<span aria-hidden="true">&times;</span>
								</b-button>
							</div>
							<div class="modal-body" style="font-size:80%">
								Det ser ud til at du allerede er oprettet som behandler.<br />
								<br />
								<router-link to="/">Klik 'Log ind' øverst i højre menu.</router-link>
							</div>
						</div>
					</div>
				</div>
			</div>
		</transition>
		<!--- *********************************** -->
		<div class="title-container" v-if="registrationComplete">
			<div class="tittle">
				Oprettelse allerede foretaget
			</div>
			<div class="description">
				Det ser ud til at du allerede har gennemgået oprettelses-flowet. <br />
				Afvent derfor bekræftigelsesemailen fra systemet for at
				fuldføre oprettelsen.
			</div>
		</div>

		<div v-if="!registrationComplete">
			<div class="title-container">
				<div class="tittle">
					Opret gratis behandlerprofil
				</div>
				<div class="description">
					Indtast herunder dine oplysninger til oprettelse af din personlige profil på minHolistiskeBehandler.dk.
					Herefter sender vi dig et link til administration og opsætning af din helt egen personlige
					profilside.
				</div>
			</div>

			<div class="personal-information" v-if="show">
				<form class="row g-3 needs-validation" @submit="saveForm" style="padding:0px;border:0px solid lightblue"
					novalidate>
					<b-container>
						<b-form-row>
							<b-col class="col-12 col-sm-12 col-md-6 px-2">
								<form-group class="mlabel" :validator="$v.email" label="E-mail" description="">
									<b-form-input type="text" v-model="email" />
									<div class="info" v-if="didAnEmailLookup && !readyForInput">Din email er allerede
										registreret som bruger på minSundhed.com. Klik på knappen 'Opret mig som
										behandler' for at fortsætte
									</div>
								</form-group>
							</b-col>
							<b-col class="col-12 col-sm-12 col-md-6 col-lg-6 px-2" v-if="isAdmin">
								<form-group class="mlabel">
									<input type="checkbox" v-model="sendEmailToAdmin" class="cust" />
									&nbsp;<span style="color:#ff0000;font-size:80%">[Admin] Send opret email til
										dig?</span>
								</form-group>
							</b-col>
						</b-form-row>

						<b-form-row>
							<b-col class="col-12 col-sm-12 col-md-6 px-2">
								<form-group :validator="$v.firstName" class="mlabel" label="Indtast fornavn">
									<b-form-input type="text" @blur="$v.firstName.$touch()" v-model="firstName"
										:disabled="!readyForInput" />
								</form-group>
							</b-col>
							<b-col class="col-12 col-sm-12 col-md-6 col-lg-6 px-2">
								<form-group :validator="$v.lastName" class="mlabel" label="Indtast efternavn">
									<b-form-input type="text" @blur="$v.lastName.$touch()" v-model="lastName"
										:disabled="!readyForInput" />
								</form-group>
							</b-col>
						</b-form-row>
						<b-form-row>
							<b-col class="col-12 col-sm-12 col-md-6 px-2">
								<b-form-group class="mlabel" label="Fødselsdato" label-for="birthday" description="">
									<b-form-input type="text" v-mask="'##-##-####'" v-model="birthday"
										:disabled="!readyForInput" @blur="$v.birthday.$touch()" placeholder="24-12-2001"
										class="date" />
								</b-form-group>
							</b-col>
							<b-col class="col-12 col-sm-12 col-md-6 px-2">
								<b-form-group label="Køn*" label-for="gender" description="">
									<b-form-radio-group v-model="gender" :disabled="!readyForInput" :options="genders">
									</b-form-radio-group>
								</b-form-group>
							</b-col>
						</b-form-row>
						<b-form-row>
							<b-col class="col-12 col-sm-12 col-md-6 px-2">
								<form-group class="mlabel" label="Telefonnummer" :validator="$v.phoneNumber"
									description="">
									<b-form-input type="text" v-mask="'########'" @blur="$v.phoneNumber.$touch()"
										v-model="phoneNumber" :disabled="!readyForInput" />
								</form-group>
							</b-col>
							<b-col class="col-12 col-sm-12 col-md-6 px-2">
								<form-group class="mlabel" label="Vælg brugernavn" :validator="$v.userName"
									label-for="userName" description="">
									<b-form-input type="text" @blur="$v.userName.$touch()" :disabled="!readyForInput"
										v-model="userName" />
								</form-group>
							</b-col>
						</b-form-row>

						<b-form-row>
							<b-col class="col-12 col-sm-12 col-md-6 px-2">
								<form-group class="mlabel" label="Vælg kodeord" :validator="$v.password" description="">
									<b-form-input v-model="password" @blur="$v.password.$touch()"
										:disabled="!readyForInput" type="password" />
								</form-group>
							</b-col>
							<b-col class="col-12 col-sm-12 col-md-6 px-2">
								<form-group class="mlabel" label="Gentag kodeord" :validator="$v.confirmPassword"
									description="">
									<b-form-input v-model="confirmPassword" @blur="$v.confirmPassword.$touch()"
										:disabled="!readyForInput" type="password" />
								</form-group>
							</b-col>
						</b-form-row>
						<b-form-row>
							<b-col class="px-2">
								<span class="required">* Disse felter skal udfyldes</span>
							</b-col>
						</b-form-row>
						<b-form-row>
							<b-col class="px-2">
								<div class="full">
									<input type="checkbox" v-model="terms" class="cust" />
									<span @click="showTerms(2)" class="highlighted_link"><span>Jeg accepterer
											brugerbetingelserne</span></span>
									<br />
									<input type="checkbox" v-model="coc" class="cust" />
									<span @click="showTerms(1)" class="highlighted_link"><span>Jeg er enig i
										minHolistiskeBehandler Code of Conduct</span></span>

									<terms-and-conditions ref="termsAndConditions"></terms-and-conditions>
									<div class="help-block" v-if="!$v.terms.checked || !$v.coc.checked">Husk at
										acceptere for at fortsætte</div>
								</div>
							</b-col>
						</b-form-row>
						<b-form-row style="margin-top:20px">
							<b-col class="px-5" cols="12" style="text-align: center">
								<button type="submit" @click="saveForm" :disabled="!okToSubmit" class="mx-auto"
									:class="{ 'btn-blue-border_disabled': !okToSubmit, 'btn-blue-border': okToSubmit }">
									Opret mig som behandler
								</button>
							</b-col>
						</b-form-row>
					</b-container>
				</form>
				<!-- <button id="sub" type="submit" @click="checkForm">checkForm</button> -->
			</div>
		</div>
	</section>
</template>

<style scoped>
div.full>input {
	position: relative !important;
	top: -8px !important;
	margin-right: 5px;
}

div.full>div {
	padding-top: 0px;
}

div.info {
	margin-top: 5px;
	font-family: roboto;
	font-weight: 400;
	background-color: #035882;
	border: 1px solid #00aaff;
	color: white;
	padding: 8px;
	border-radius: 4px;
}
</style>
<style type="text/css">
label {
	font-size: 15px !important
}
</style>
<style lang="scss" scoped>
@use "./colors.scss" as *;

.form-control input {
	width: 100%
}

.full {
	display: inline-block;
	width: 100%;
	// float: left;
	text-align: left;
}

.date {
	width: 30%
}

.highlighted_link {
	text-decoration: underline;
	color: #000;
	vertical-align: super;
	font-size: 80%
}

.spam-info {
	font-weight: 500;
	line-height: 26px;
	text-align: center;
	color: $dark-red;
	max-width: 690px;
	margin-bottom: 50px;
}

.required {
	font-size: 12px;
	font-weight: 600;
	line-height: 34px;
	letter-spacing: 0.5px;
	color: $rhino-gray;
}

.personal-information {
	max-width: 900px;
	margin-bottom: 60px !important;
	width: 100%;
	margin: 0 auto;

	div.cust {
		border: 0px solid red;

		.custom-control-input {
			border: 1px solid blue;
			position: relative !important;
			top: -8px !important;
		}
	}

	.v-label {
		&--active {
			font-size: 12px;
			transform: translateY(-18px) scale(1);
			font-weight: 600;
		}

	}

	.v-radio {
		margin-bottom: 0;
	}

	.form-row {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.half {
		max-width: 400px;
		width: 100%;
	}

	.gender-container {
		display: flex;
		align-items: center;

		span {
			text-align: left;
			font-size: 12px;
			font-weight: 600;
			line-height: 1;
			letter-spacing: 0.5px;
			color: $rhino-gray;
			margin-right: 40px;
		}

	}

	.birth-container {
		.v-label {
			color: $rhino-gray;
			font-weight: 600;
		}

		input {
			width: 80px;
			height: 80px;
			border: solid 1px $storm-gray;
			text-align: center;
			font-size: 22px;

			&+input {
				margin-left: 20px;
			}

		}
	}

	.submit-btn-m-tb {
		margin-top: 140px;
		margin-bottom: 54px;
	}

	.btn-blue-border {
		width: 250px;
		height: 65px;
		border-radius: 40px;
		border: 2px solid $minbehandlerBlue;
		background-color: $white;
		font-family: Raleway;
		font-size: 14px;
		font-weight: 600;
		line-height: 26px;
		letter-spacing: 0.5px;
		text-align: center;
		color: $minbehandlerBlue;

		&:hover {
			border: 2px solid $minbehandlerBlue;
			background-color: $minbehandlerBlue;
			color: $white;
		}

	}

	.btn-blue-border_disabled {
		width: 250px;
		height: 65px;
		border-radius: 40px;
		border: 2px solid $minbehandlerBlue;
		background-color: $lighter-gray !important;
		cursor: not-allowed;
		font-family: Raleway;
		font-size: 14px;
		font-weight: 600;
		line-height: 26px;
		letter-spacing: 0.5px;
		text-align: center;
		color: $minbehandlerBlue !important;
	}

}

.title-container {
	width: 100%;
	margin: 0 auto;

	.tittle {
		font-weight: 200;
		line-height: 48px;
		text-align: center;
		color: $black;
	}

	.description {
		text-align: center;
		color: $charcoal-gray;
	}

}


.registration-info-container {
	max-width: 275px;
	width: 100%;
	margin: 0 auto;

	.conditions {
		font-weight: 500;
		color: $lava-gray;
	}

	.highlighted {
		color: $green;
		font-size: 12px;
		line-height: 20px;
		text-align: center;
		margin-top: 40px;
		font-weight: bold;

		&_link {
			text-decoration: none;
			color: inherit;
		}

	}
}

/*small phoneNumber*/
@media (max-width: 374px) {
	.date {
		width: 100%
	}

	.title-container {
		max-width: 100h;

		.tittle {
			font-size: 38px;
		}

		.description {
			margin-top: 10px;
			margin-bottom: 20px;
			color: $charcoal-gray;
			font-size: 14px;
			font-weight: 600;
			line-height: 28px;
			text-align: left;
		}

	}

	.personal-information {
		.no-overflow {
			.v-label {
				overflow: hidden;
			}
		}

		max-width: 335px;

		.submit-btn-m-tb {
			margin-top: 74px;
			margin-bottom: 74px;
		}

		.form-row {
			flex-direction: column;
		}
	}

	.registration-info-container {
		.conditions {
			font-size: 12px;
			line-height: 20px;
			color: $lava-gray;
		}

		.highlighted {
			margin-top: 40px;
			margin-bottom: 100px;
			font-weight: bold;
		}

	}
}

/*big phoneNumber*/
@media (min-width: 375px) {
	.date {
		width: 100%
	}

	.title-container {
		max-width: 335px;

		.tittle {
			font-size: 38px;
		}

		.description {
			margin-top: 10px;
			margin-bottom: 20px;
			color: $charcoal-gray;
			font-size: 14px;
			font-weight: 500;
			line-height: 28px;
			text-align: left;
		}

	}

	.personal-information {
		.no-overflow {
			.v-label {
				overflow: hidden;
			}

		}

		max-width: 335px;

		.submit-btn-m-tb {
			margin-top: 74px;
			margin-bottom: 74px;
		}

		.form-row {
			flex-direction: column;
		}

	}

	.registration-info-container {
		.conditions {
			font-size: 12px;
			line-height: 20px;
			color: $lava-gray;
		}

		.highlighted {
			color: $light-green;
			font-size: 12px;
			line-height: 20px;
			text-align: center;
			margin-top: 40px;
			margin-bottom: 100px;
			font-weight: bold;
		}

	}
}

/*small tablet*/
@media (min-width: 768px) {
	.date {
		width: 50%
	}

	.personal-information {
		width: 100%;
		min-width: 700px !important;

		.submit-btn-m-tb {
			margin-top: 30px;
			margin-bottom: 54px;
		}

	}

	.form-row {
		.half {
			margin-bottom: 10px;
		}

	}

	.title-container {
		max-width: 600px;

		.description {
			margin-bottom: 25px;
			margin-top: 30px;
			font-weight: 500;
			font-size: 15px;
			line-height: 26px;
			color: $rhino-gray;
			text-align: center;
		}

	}

	.registration-info-container {
		max-width: 713px;
		margin-top: 50px;
		margin-bottom: 40px;

		.conditions {
			font-size: 12px;
			font-weight: 500;
			line-height: 20px;
			color: $lava-gray;
		}

		.highlighted {
			color: $green;
			font-size: 14px;
			line-height: 24px;
			margin-top: 40px;
			font-weight: bold;
			margin-bottom: 140px;
		}

	}
}

/*big tablet*/
@media (min-width: 993px) {

	.title-container {
		margin-top: -20px;
		max-width: 730px;

		.tittle {
			font-size: 48px;
			line-height: 48px;
			color: $dark-blue;
		}

	}

	.description {
		margin-top: 34px;
		font-size: 15px;
	}

	.personal-information {
		.no-overflow {
			.v-label {
				overflow: unset;
			}

		}

		max-width: 900px;

		.submit-btn-m-tb {
			margin-top: 140px;
			margin-bottom: 54px;
		}

		.form-row {
			flex-direction: row;
		}

	}
}

/*laptop*/
@media (min-width: 1264px) {}

/*pc*/
@media (min-width: 1664px) {}
</style>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import { required, numeric, email, sameAs, minLength, maxLength } from 'vuelidate/lib/validators'
import TermsAndConditions from './TermsAndConditions.vue';

function isEmailAvail(val) {
	if (!val) return false;
	let res = this.emailAvailable;
	if (!res)
		this.$v.email.$touch();
	return res
}

function isUsernameAvail(val) {
	if (!val) return false;
	let res = this.usernameAvailable;
	if (!res)
		this.$v.userName.$touch();
	return res
}

export default {
	data() {
		return {
			genders: [{ text: "Mand", value: "Male" }, { text: "Kvinde", value: "Female" }],
			show: true,
			sendEmailToAdmin: true,
			registrationComplete: false,
			valid: false,
			gender: false,
			email: "",
			emailAvailable: true,
			isAlreadyPractitioner: false,
			password: "",
			confirmPassword: "",
			userName: "",
			usernameAvailable: true,
			firstName: null,
			lastName: null,
			phoneNumber: "",
			birthday: "",
			terms: false,
			coc: false,
			newUserId: null,
			didAnEmailLookup: false,
		};
	},
	watch: {
		async email(val) {
			//debugger // eslint-disable-line no-debugger
			if (!this.$v.email.email)
				return;
			let res = await this._checkIfEmailExist(val);
			this.emailAvailable = res.emailAvailable;
			this.isAlreadyPractitioner = res.isAlreadyPractitioner;
			this.didAnEmailLookup = true;
		},
		userName: function (val) {
			this._checkIfUserNameExist(val);
		},
	},
	computed: {
		...mapGetters(["isAdmin", "userId"]),
		readyForInput() {
			return this.emailAvailable && this.didAnEmailLookup;
		},
		okToSubmit() {
			let res = !this.$v.$invalid || !this.emailAvailable;
			if (!this.$v.terms.checked || !this.$v.coc.checked)
				res = false;
			return res;
		}
	},
	validations: {
		firstName: { required, minLength: minLength(2) },
		lastName: { required, minLength: minLength(2) },
		phoneNumber: { numeric, minLength: minLength(8), maxLength: maxLength(8) },
		//email: { required, email },
		email: { required, email, isEmailAvail },
		userName: { required, minLength: minLength(4), isUsernameAvail },
		password: { required, minLength: minLength(6) },
		birthday: { minLength: minLength(10), maxLength: maxLength(10) },
		confirmPassword: { minLength: minLength(6), sameAsPassword: sameAs("password") },
		terms: {
			checked(val) {
				return val;
			}
		},
		coc: {
			checked(val) {
				return val;
			}
		}
	},
	methods: {
		async _emailExistMinSundhed(email) {
			email = encodeURIComponent(email);
			try {
				const response = await axios.get(process.env.VUE_APP_MS_API + `/auth/exist?email=${email}`);
				return response.data;
			}
			catch (error) {
				console.log(error);
			}
		},
		async _isAlreadyPractitioner(userid) {
			try {
				const response = await axios.get(`/practitioner/ispractitioner?userid=${userid}`);
				return response.data;
			}
			catch (error) {
				console.log(error);
			}
		},
		async _checkIfEmailExist(input) {
			if (input) {
				const reply = {
					emailAvailable: false,
					isAlreadyPractitioner: false,
					userId: ""
				};
				let emailResponse = await this._emailExistMinSundhed(input);
				reply.emailAvailable = !emailResponse.exists;
				reply.userId = emailResponse.userId;
				this.newUserId = emailResponse.userId;
				if (!reply.emailAvailable) //maybe user is already practitioner, but forgot about it? :)
					reply.isAlreadyPractitioner = await this._isAlreadyPractitioner(emailResponse.userId);
				return reply;
			}
			return null;
		},
		_checkIfUserNameExist(input) {
			if (input && input.length > 3 && input.length < 30) {
				let userName = encodeURIComponent(input);
				axios.get(process.env.VUE_APP_MS_API + `/auth/exist?userName=${userName}`)
					.then((response) => {
						let data = response.data;
						this.usernameAvailable = !data.exists;
					})
					.catch(error => {
						console.log(error);
					});
			}
		},
		async createUser(data) {
			return axios.post(process.env.VUE_APP_MS_API + `/auth/register`, data, { headers: { "Content-type": "application/json", } })
				.then(res => res.data.userId)
				.catch(error => {
					console.log("Error creating user!!");
					console.log(error);
					let err = [];
					if (error && error.response && error.response.data) {
						for (var o in error.response.data.modelState)
							if (Object.prototype.hasOwnProperty.call(error.response.data.modelState, o))
								err.push(error.response.data.modelState[o]);
						console.log(err.flat().join("\n"));
					}
					alert("Der var et problem med at oprette din profil. Se evt. følgende besked: \n" + err.flat().join("\n") + "\n" + error);
					return "";
				});
		},
		async createPractitioner(userId) {
			let overrideNotification = !this.isAdmin ? 'false' : this.sendEmailToAdmin;
			return axios.get(`/practitioner/registerpractitioner?userId=${userId}&overridenotification=${overrideNotification}`)
				.then(() => {
					console.log("createPractitioner: ok");
					return 0;
				})
				.catch(error => {
					console.log("ERROR RECEIVE! error\n" + error);
					return -1;
				});
		},
		async saveForm(e) {
			//debugger // eslint-disable-line no-debugger
			console.log("Saving form... registering user");
			e.preventDefault();
			if (this.emailAvailable)
				this.$v.$touch();
			console.log("\t1");
			const data = {
				OverrideNotification: {
					UserId: !this.isAdmin ? '' : this.sendEmailToAdmin ? this.userId : '',
				},
				userInfo: {
					FirstName: this.firstName,
					LastName: this.lastName,
					Email: this.email,
					Password: this.password,
					ConfirmPassword: this.confirmPassword,
					Gender: this.gender,
					Birthday: this.birthday,
					PhoneNumber: this.phoneNumber,
					UserName: this.userName,
					IsBehandler: true,
				}
			};
			console.log("\t.emailAvailable:" + this.emailAvailable);
			if (this.emailAvailable)
				this.newUserId = await this.createUser(data); //first register with minsundhed api
			console.log("\tthis.newUserId: " + this.newUserId);
			if (this.newUserId == "")
				return; //something went wrong?
			console.log("\tcreating practitioner");
			const result = await this.createPractitioner(this.newUserId);
			console.log("result: " + result);
			if (result != 0) {
				alert("Der var et problem med at registrere dig som behandler. Prøv evt. igen eller kontakt supporten.");
				return;
			}
			this.registrationComplete = true;
			this.$emit("fillInformationCompleted");
		},
		async checkForm(e) {
			/* eslint-disable */
			console.log("checkForm " + e);
		},
		showTerms(index) {
			this.$refs.termsAndConditions.show(index);
		},
		popupwindow() {
			var left = (screen.width / 2) - (800 / 2);
			var top = (screen.height / 2) - (800 / 2);
			window.open("/betingelser", "Legal Text", "toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=" + 800 + ", height=" + 800 + ", top=" + top + ", left=" + left);
			//return false;
		},
	},
	created() {
		//this.init();
	},
	components: { TermsAndConditions }
}
</script>
