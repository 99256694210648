import { render, staticRenderFns } from "./LiveEventRabatter.vue?vue&type=template&id=3804817a&scoped=true"
import script from "./LiveEventRabatter.vue?vue&type=script&lang=js"
export * from "./LiveEventRabatter.vue?vue&type=script&lang=js"
import style0 from "./LiveEventRabatter.vue?vue&type=style&index=0&id=3804817a&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3804817a",
  null
  
)

export default component.exports